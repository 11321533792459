"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Load {
    constructor(lightLoad, mediumLoad, heavyLoad, liftOverHead, liftOffGround, pushOrDrag) {
        this.id = (0, uuid_1.v4)();
        this.lightLoad = lightLoad;
        this.mediumLoad = mediumLoad;
        this.heavyLoad = heavyLoad;
        this.liftOverHead = liftOverHead;
        this.liftOffGround = liftOffGround;
        this.pushOrDrag = pushOrDrag;
    }
}
exports.default = Load;
