"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Ability {
    constructor(name) {
        this.id = (0, uuid_1.v4)();
        this.name = name;
        this.score = 10;
        this.modifier = 0;
        this.temporaryScore = 0;
        this.temporaryModifier = 0;
    }
}
exports.default = Ability;
