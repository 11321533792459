"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Attack {
    constructor(attack, attackBonus, critical, weight, type, range, notes, damage) {
        this.id = (0, uuid_1.v4)();
        this.attack = attack;
        this.attackBonus = attackBonus;
        this.critical = critical;
        this.weight = weight;
        this.type = type;
        this.range = range;
        this.notes = notes;
        this.damage = damage;
    }
}
exports.default = Attack;
