"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Money {
    constructor(cp, sp, gp, pp) {
        this.id = (0, uuid_1.v4)();
        this.cp = cp;
        this.sp = sp;
        this.gp = gp;
        this.pp = pp;
    }
}
exports.default = Money;
