"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class ArmorClass {
    constructor(total, armorBonus, shieldBonus, dexModifier, sizeModifier, naturalArmor, deflectionBonus, luckBonus, insightBonus, moraleBonus, miscModifier, touchAc, flatFootedAc) {
        this.id = (0, uuid_1.v4)();
        this.total = total;
        this.armorBonus = armorBonus;
        this.shieldBonus = shieldBonus;
        this.dexModifier = dexModifier;
        this.sizeModifier = sizeModifier;
        this.naturalArmor = naturalArmor;
        this.deflectionBonus = deflectionBonus;
        this.luckBonus = luckBonus;
        this.insightBonus = insightBonus;
        this.moraleBonus = moraleBonus;
        this.miscModifier = miscModifier;
        this.touchAc = touchAc;
        this.flatFootedAc = flatFootedAc;
    }
}
exports.default = ArmorClass;
