"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Initiative {
    constructor(total, dexModifier, miscModifier) {
        this.id = (0, uuid_1.v4)();
        this.total = total;
        this.dexModifier = dexModifier;
        this.miscModifier = miscModifier;
    }
}
exports.default = Initiative;
