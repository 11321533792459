"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class ProtectiveItemsTotal {
    constructor(acBonus, dexMax, checkPenalty, spellFailure, weight, specialProperties) {
        this.id = (0, uuid_1.v4)();
        this.acBonus = acBonus;
        this.dexMax = dexMax;
        this.checkPenalty = checkPenalty;
        this.spellFailure = spellFailure;
        this.weight = weight;
        this.specialProperties = specialProperties;
    }
}
exports.default = ProtectiveItemsTotal;
