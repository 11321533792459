"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class HitPoint {
    constructor(total, damageReduction, current, nonlethalDamage) {
        this.id = (0, uuid_1.v4)();
        this.total = total;
        this.damageReduction = damageReduction;
        this.current = current;
        this.nonlethalDamage = nonlethalDamage;
    }
}
exports.default = HitPoint;
