"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitle = exports.getHomeTitle = exports.calcSkillModifier = exports.calcGrappleModifier = exports.calcSavingThrow = exports.calcInitiative = exports.calcArmorClass = exports.calcModifier = exports.getAbilityPosition = exports.getSTCorrespondingAbility = void 0;
const ABILITY_POSITION = {
    '【筋】': 0,
    '【敏】': 1,
    '【耐】': 2,
    '【知】': 3,
    '【判】': 4,
    '【魅】': 5,
};
const ST_CORRESPONTING_ABILITY = {
    '頑健【耐久力】': '【耐】',
    '反応【敏捷力】': '【敏】',
    '意志【判断力】': '【判】',
};
const getSTCorrespondingAbility = (name) => ST_CORRESPONTING_ABILITY[name];
exports.getSTCorrespondingAbility = getSTCorrespondingAbility;
const getAbilityPosition = (ability) => ABILITY_POSITION[ability];
exports.getAbilityPosition = getAbilityPosition;
const calcModifier = (xs) => Math.floor((Number(xs[0]) - 10) / 2);
exports.calcModifier = calcModifier;
const calcArmorClass = (xs) => xs.reduce((acc, cur) => Number(acc) + Number(cur), 10);
exports.calcArmorClass = calcArmorClass;
const calcInitiative = ([dex, misc]) => (0, exports.calcModifier)([dex]) + Number(misc);
exports.calcInitiative = calcInitiative;
const calcSavingThrow = ([base, dex, magic, misc, temp]) => Number(base)
    + (0, exports.calcModifier)([dex]) + Number(magic) + Number(misc) + Number(temp);
exports.calcSavingThrow = calcSavingThrow;
const calcGrappleModifier = ([bab, str, size, misc]) => Number(bab) + (0, exports.calcModifier)([str])
    + Number(size) + Number(misc);
exports.calcGrappleModifier = calcGrappleModifier;
const calcSkillModifier = ([ability, rank, penalty, misc]) => (0, exports.calcModifier)([ability])
    + Number(rank) + Number(penalty) + Number(misc);
exports.calcSkillModifier = calcSkillModifier;
const getHomeTitle = () => 'D&D 3.5版 キャラクターデータベース';
exports.getHomeTitle = getHomeTitle;
const getTitle = (char) => (char.pcName === '' ? '名前なし' : char.pcName) + ' - ' + (0, exports.getHomeTitle)();
exports.getTitle = getTitle;
