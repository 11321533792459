"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class SavingThrow {
    constructor(name, total, baseSave, abilityModifier, magicModifier, miscModifier, temporaryModifier) {
        this.id = (0, uuid_1.v4)();
        this.name = name;
        this.total = total;
        this.baseSave = baseSave;
        this.abilityModifier = abilityModifier;
        this.magicModifier = magicModifier;
        this.miscModifier = miscModifier;
        this.temporaryModifier = temporaryModifier;
    }
}
exports.default = SavingThrow;
