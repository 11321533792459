"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Posession {
    constructor(item, weight) {
        this.id = (0, uuid_1.v4)();
        this.item = item;
        this.weight = weight;
    }
}
exports.default = Posession;
