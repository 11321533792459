"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class GrappleModifier {
    constructor(total, bab, strengthModifier, sizeModifier, miscModifier) {
        this.id = (0, uuid_1.v4)();
        this.total = total;
        this.bab = bab;
        this.strengthModifier = strengthModifier;
        this.sizeModifier = sizeModifier;
        this.miscModifier = miscModifier;
    }
}
exports.default = GrappleModifier;
